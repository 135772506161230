:root {
  --white: #ffffff;
  --white-transparent: rgba(255, 255, 255, 0.451);
  --black: #000000;
  --grey: #f0f0f0;
  --dark-grey: #525252;
  --grey-shadow: rgba(129, 129, 129, 0.171);
  --warning:#ffdb77;
  --warning-dark:#ffbb00;
  --border-size: 1px;
  --border-radius: 5px;
  --input-font-size: 12px;
  /* bixal custom */
  --bixal-red:#e02f00;
  --bixal-blue:#bee9f6;
  --bixal-maroon: #55052a;
  --bixal-purple: #7d0096;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif, "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
  background-color: var(--grey);
  font-size: 12px;
}

.browser-alert {
  height: 75px;
  background: var(--warning);
  border-bottom: 3px solid var(--warning-dark);
  position: relative;
  background-image: url('./icons/icon-warning.svg');
  background-position: center
}

.browser-alert--content {
  text-align: center;
  background: var(--warning-dark);
  max-width: 55%;
  margin: 0 auto;
  font-size: 15px;
  font-weight: bold;
  color: var(--black);
  padding: 10px;
  position: relative;
  top: 40px;
}

.top-banner {
  background: var(--white);
  width: 100%;
}

.top-banner--content {
  max-width: 1000px;
  margin: 0px auto;
}

#logo {
  width: 150px;
  margin: 25px 0px 25px 0px;
}

.content-wrapper {
  max-width: 1000px;
  margin: auto;
  margin-bottom: 50px;
}

a.skip-main {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}

a.skip-main:focus, a.skip-main:active {
  color: #fff;
  background-color:#000;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  padding:5px 0px;
  text-align:center;
  font-size:12px;
  z-index:999;
}

#main:focus {
  outline: none;
}

.heading {
  font-weight: 100;
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 50px;
  text-transform: uppercase;
}

.heading:before {
  content: '|';
  padding-right: 10px;
  color: var(--bixal-red);
  position: relative;
  top: -1px;
}

.heading:after {
  content: '|';
  padding-left: 10px;
  color: var(--bixal-red);
  position: relative;
  top: -1px;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.container-form {
  width: calc(50% - 50px);
  padding: 0px 25px;
  min-height: 500px;
}

.container-signature {
  background: var(--white);
  width: 50%;
  -webkit-box-shadow: -1px 1px 11px 0px var(--grey-shadow);
  -moz-box-shadow:    -1px 1px 11px 0px var(--grey-shadow);
  box-shadow:         -1px 1px 11px 0px var(--grey-shadow);
}

.email-heading {
  height: 30px;
}

.email-heading-features {
  width: 50px;
  display: flex;
  padding: 10px 0px 0px 10px;
  justify-content: space-around;
}

.email-heading-feature {
  width: 10px;
  height: 10px;
  background: var(--grey);
  border-radius: 30px;
}

.email-heading-line {
  display: flex;
  flex-direction: column;
  margin: 15px;
}

.email-heading-line--item {
  color: var(--dark-grey);
  margin-bottom: 5px;
  padding-bottom: 4px;
}

.container-signature-preview {
  padding: 20px 0px 20px 15px;
}


a {
  color: var(--white);
}

a.phone-link {
  text-decoration: "none"
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
  border: var(--border-size) solid var(--dark-grey);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  border: var(--border-size) solid var(--dark-grey);
  background-color: var(--grey);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: none;
}

.slider:hover {
  background: var(--white);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius:5px;
}

.slider.round:before {
  border-radius: 18%;
}

.text-toggle {
  margin-bottom: 10px;
}

.text-toggle--description {
  position: relative;
  bottom: 10px;
  left: 10px;
  text-transform: uppercase;
  font-size: var(--input-font-size);
  color: var(--dark-grey);
}

fieldset {
  border: none;
  background-image: none;
  margin: 0px;
  padding: 0px;
}

fieldset label {
  color: var(--dark-grey);
  font-size: 12px;
  text-transform: uppercase;
}

.hidden-label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

fieldset > div {
  margin: 20px 0px;
}

input[type="text"],
input[type="email"],
input[type="tel"].PhoneInputInput,
.input {
  position: relative;
  background: none;
  border: var(--border-size) solid var(--dark-grey);
  border-radius: var(--border-radius);
  height: 20px;
  width: calc(100% - 14px);
  margin: 10px 0px;
  padding: 10px 0px 10px 10px;
  color: var(--dark-grey);
  /* text-transform: uppercase; */
  font-size: var(--input-font-size);
}

input[type="text"]::placeholder,
input[type="email"]::placeholder
input[type="tel"].PhoneInputInput::placeholder,
.input::placeholder {
  color: var(--dark-grey);
  text-transform: uppercase;
  font-size: var(--input-font-size);
}

input[type="tel"].PhoneInputInput {
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button-clipboard-copy {
  position: relative;
  color: var(--dark-grey);
  background: none;
  display: inline-block;
  cursor: pointer;
  padding: 14px 40px 14px 12px;
  text-decoration: none;
  border: var(--border-size) var(--dark-grey) solid;
  border-radius: var(--border-radius);
  font-size: var(--input-font-size);
  font-weight: bold;
  margin: 25px 0px;
  text-transform: uppercase;
}

.button-clipboard-copy:hover {
  color: var(--dark-grey);
  background: var(--white);
}

.button-clipboard-copy:after {
  content: url('./icons/icon-copy-dark-grey.svg');
  position: absolute;
  right: 12px;
  top: 11px;
  width: 20px;
  height: 20px;
}

input:hover,
select:hover {
  background: white;
}

/* Base for label styling */
input[type="checkbox"]:not(:checked),
input[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
input[type="checkbox"]:not(:checked) + label,
input[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
}

/* checkbox aspect */
input[type="checkbox"]:not(:checked) + label:before,
input[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: -4px;
  width: 20px;
  height: 20px;
  background: var(--grey);
  border-radius: var(--border-radius);
  border: var(--border-size) solid var(--dark-grey);
}

input[type="checkbox"]:hover + label:before,
input[type="checkbox"]:hover + label:after {
  background: var(--white);
  border-radius: var(--border-radius);
}

/* checked mark aspect */
input[type="checkbox"]:not(:checked) + label:after,
input[type="checkbox"]:checked + label:after {
  content: url('./icons/icon-checkmark.svg');
  position: absolute;
  top: -3px;
  left: 1px;
  width: 20px;
  height: 20px;
}

/* checked mark aspect changes */
input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}
input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

/* selects */
select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  border: none;
  border-radius: var(--border-radius);
  box-shadow: none;
  color: var(--dark-grey);
  font-size: var(--input-font-size);
  text-indent: 2px;
  margin: 10px 0px 0px 0px;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 44px;
  text-transform: uppercase;
  background: var(--grey);
  border: var(--border-size) solid var(--dark-grey);
}

/* input focus */
.slider:focus,
select:focus,
button:focus,
textarea:focus,
input[type="checkbox"]:focus:not(:checked) + label:before,
input[type="checkbox"]:focus:checked + label:before,
input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input:focus + .slider {
  box-shadow: 0 1px 1px var(--grey-shadow), 0 0 8px var(--grey-shadow);
  outline: 0 none;
  background: white;
}

.sucess-msg {
  color: #027804;
  position: absolute;
  font-weight: bold;
}

footer {
  background: var(--bixal-maroon);
  color: var(--white);
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 40px 0px;
}

.footer-content {
  max-width: 1000px;
  margin: auto;
  display: flex;
  color: var(--white);
  font-size: 12px;
  line-height: 1.8;
  font-weight: 500;
}

.footer-content h2 {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0px;
}

.footer-content ul {
  margin: 0px;
  padding: 0px;
}

.footer-content li {
  margin-bottom: 10px;
  list-style: none;
}

.footer-content--branding {
  padding: 0px 40px 0px 0px;
}

.icon-b {
  background: url('./icons/icon-bixal-b--red.svg');
  width: 100px;
  height: 150px;
  background-size: cover;
  background-repeat: none;
  margin-bottom: 20px;
}

.footer-content--howto,
.footer-content--help {
  padding: 0px 40px;
}

.footer-content--howto {
  border-right: 1px solid var(--white-transparent);
  border-left: 1px solid var(--white-transparent);
}

@media only screen and (max-width: 1036px) {
  .top-banner--content,
  .content-wrapper,
  .footer-content {
    margin: 0px 20px;
  }
}

/* mobile */
@media only screen and (max-width: 720px) {
  .app {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .top-banner--content,
  .content-wrapper,
  .footer-content {
    margin: 0px auto;
  }

  #logo {
    margin: 25px 0px 25px 25px;
  }

  .scroll-on {
    display: none;
  }

  .heading {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .container-form {
    width: 100%;
    padding: 50px 25px;
  }

  .container-signature {
    height: auto;
    margin: 0 auto;
    width: 90%;
  }

  .footer-content {
    display: block;
    margin: 0px 20px;
  }

  .footer-content--howto,
  .footer-content--branding {
    padding-left: 15px;
    margin-bottom: 40px;
    padding-bottom: 20px;
    border: 0px;
    border-bottom: 1px solid var(--white-transparent);
  }

  .footer-content--help {
    padding-left: 15px;
    border: 0px;
  }

}
